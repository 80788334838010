// const dayjs = require("dayjs")

module.exports = {
	APIServer: process.env.API_SERVER,
	// APIAssetKey: process.env.API_ASSET_KEY, // TODO - remove, obsolete
	cookieConsentName: "weblica-analytics-consent",
	vatPercent: 25,
	altConversionRate: 7.5345,
	defaultLanguage: "hr",
	multiLanguage: {
		active: true,
		alwaysGenerate: true, // if set, system will generate all language pages (further options define behavior)
		inactiveVariant: "unsupported_language_page", // force_default_language || unsupported_language_page - type of generated page
		dummyRedirectTimeOut: 5, // if set, automatic redirect will be done after given number of seconds
	},
	languages: {
		hr: {
			available: true, // available in language selector for front-end
			option: "HR",
			name: "Hrvatski",
			flagIcon: "flag-croatia.png",
			dateFormat: "DD[.] MMMM YYYY.", // [.] = naziv mjeseca u genitivu (npr. studenoga)
			dateFormatAlt: "DD. MMMM YYYY.", // . = naziv mjeseca u nominativu (npr. studeni)
			dateFormatISO: "YYYY-MM-DD",
			dateFormatNumbers: "DD.MM.YYYY.",
			timeFormat: "HH:mm",
			at: "u",
			locale: "hr-HR",
			htmlMetaLanguage: "hr-HR",
			currency: {
				code: "EUR",
				symbol: "€",
			},
			currencyAlt: {
				code: "HRK",
				symbol: "kn",
			},
			ordinal: (n) => {
				const r = `[${n}.]`
				// console.log("ordinal", n, "-->", r)
				return r
			},
		},
		// sr: {
		// 	available: false,
		// 	option: "SR",
		// 	name: "Srpski",
		// 	dateFormat: "DD[.] MMMM YYYY.", // [.] = naziv mjeseca u genitivu (npr. studenoga)
		// 	dateFormatAlt: "DD. MMMM YYYY.", // . = naziv mjeseca u nominativu (npr. studeni)
		// 	dateFormatISO: "YYYY-MM-DD",
		// 	dateFormatNumbers: "DD.MM.YYYY.",
		// 	timeFormat: "HH:mm",
		// 	at: "u",
		// 	locale: "sr-SR",
		// 	htmlMetaLanguage: "sr-SR",
		// 	currency: {
		// 		code: "EUR",
		// 		symbol: "€",
		// 	},
		// 	currencyAlt: {
		// 		code: "HRK",
		// 		symbol: "kn",
		// 	},
		// 	ordinal: (n) => {
		// 		const r = `[${n}.]`
		// 		// console.log("ordinal", n, "-->", r)
		// 		return r
		// 	},
		// },
		en: {
			available: true,
			option: "EN",
			name: "English",
			flagIcon: "flag-usa.png",
			dateFormat: "MMMM Do, YYYY",
			dateFormatAlt: "MMMM Do, YYYY",
			dateFormatISO: "YYYY-MM-DD",
			dateFormatNumbers: "MM/DD/YYYY",
			timeFormat: "HH:mm",
			at: "at",
			locale: "en-US",
			htmlMetaLanguage: "en-US",
			currency: {
				code: "EUR",
				symbol: "€",
			},
			currencyAlt: {
				code: "HRK",
				symbol: "kn",
			},
			ordinal: (n) => {
				const s = ["th", "st", "nd", "rd"]
				const v = n % 100
				const r = `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`
				// console.log("ordinal", n, "-->", r)
				return r
			},
		},
	},
	shirtSizes: ["S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
	socialLinks: {
		facebook: "https://www.facebook.com/weblicahr/",
		instagram: "https://www.instagram.com/weblica.hr/",
		twitter: "https://twitter.com/weblicahr",
		linkedin: "https://www.linkedin.com/company/weblica",
		youtube: "https://www.youtube.com/channel/UC6LtaKpEnl_uBkB4BGBUhxQ",
	},
	devFlags: {
		buildNonVisibleEvents: false,
		showTestButtonsOnHeroSection: false,
		forceFullScheduleAndSpeakers: false // ignores visible property in the items and shows all
	},
	uiFlags: {
		showSpritesOnSectionTitles: false,
		useConfigFormat: true, // override predefined locale formats
		noDataBehavior: "TBD", // TBD - render TBD message; SKIP - do not render
		collapsibleSections: true, // if true, most sections will be collapsed on mobile devices (small screens)
		// TODO - themeForced: "browser", // null || dark || light || browser
		// TODO - themeDefault: "browser", // dark || light || browser
		index: {
			showSurveyButtonOnFinished: true,
		},
		hero: {
			showSurveyButtonOnFinished: true,
		},
		header: {
			hideOnScroll: false,
			showThemeToggleSwitch: true,
			showLanguageSelector: true,
			showEventTitle: true,
			renderEventsOnEventBar: true,
			renderEventButtonsOnEventsBar: false,
			renderEventButtonsOnMenuBar: true,
			renderOtherEventsSelection: false,
			venueLinkType: "section", // map || section
		},
		news: {
			skipUnreachedDates: true, // display text "More soon..." to mark that the presented data is not final
		},
		speakers: {
			showWorkInProgress: false, // display text "More soon..." to mark that the presented data is not final
			markKeynoteSpeaker: true, // if true, a gold star will be displayed next to speakers' name
			keynoteSpeakerFirst: false
		},
		schedule: {
			showWorkInProgress: false, // display text "More soon..." to mark that the presented data is not final
			previewMode: [], // events where schedule is rendered in preview mode - no real scheduling, no start/end times and lengths, no bell button, no full page button
			showTrackSelector: false,
			showExtraDetails: [
				"language-text",
				// "language-flag",
				"level-text",
				// "level-indicator",
				"code-text",
				// "code-indicator",
				"tags"
			],
			showStaticTags: true,
			showVenueMarker: false,
			useStars: true,
			useNotifications: true,
			showFullPageButton: true,
			useSpeakerLinks: true,
			useTrackMarkerColors: "badge", // [blank] | badge | text
			showUnifiedTrackTime: false,
			showFromToOnBreaks: false,
			markKeynote: true
		},
		tickets: {
			showRemainingTicketsCount: false,
			singleBuyButton: true,
			showPricesWithoutVAT: true, // show only entered price in "tickets"
			showPricesWithVATAddon: true, // shows pre-calculated price with added VAT as remark below base price
			showPricesWithVAT: false, // show prices in "tickets" but with added VAT percent
			showPlusVATAddOn: true, // shows "+ VAT" text after base price
			showVATRemark: false, // show remark about prices below the Buy button
			showPricesAlt: false // this is now fixed, EUR is here and going anywhere
		},
		historyGallery: {
			splitEvents: false // if true, each event will start new "line" while listing years for that event
		},
		sponsors: {
			showStarCountNumber: false,
			showLevelAs: "none", // none || stars || text
			flatten: false, // if true, all sponsors, exhibitors and partners will be is a simple flat grid; if false - everything will be grouped according to type and level
			sorted: false,
		},
		venue: {
			showGoogleMapsLink: true,
			imagesDisplay: "gallery" // separate || gallery
		},
	},
	events: {
		weblica: {
			order: 1,
			enabled: true, // if enabled, all sections with data are visible
			active: true, // if not active, event is low key (dimmer) and some feature are differently rendered
			visible: true, // if enabled, event is visible on home page
			visibleInHeader: false, // if enabled, event is visible in header (main "visible" overrides this)
			visibleInHistory: false, // if enabled, event is visible in footer history (main "visible" overrides this)
			baseUrl: "/weblica",
			ident: "weblica",
			title: "Weblica",
			logoFavIcon: "weblica-nt-nb.png",
			logoFull: "weblica-wt.svg",
			logoFullLight: "weblica-wb.svg",
			logoHeader: "weblica-nt.svg",
			logoSelection: "weblica-nt-nb.svg",
			baseColor: "#64c9dc",
			options: {},
			years: {
				2015: true,
				2016: true,
				2017: true,
				2018: true,
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: true
			},
			// survey: {
			// 	2024: true
			// }
		},
		hacklica: {
			order: 2,
			enabled: false,
			visible: false,
			active: false,
			visibleInHeader: true,
			visibleInHistory: true,
			baseUrl: "/hacklica",
			ident: "hacklica",
			title: "Hacklica",
			logoFavIcon: "hacklica-nt-nb.png",
			logoFull: "hacklica-wt.svg",
			logoFullLight: "hacklica-wb.svg",
			logoHeader: "hacklica-nt.svg",
			logoSelection: "hacklica-nt-nb.svg",
			baseColor: "#b5d452",
			options: {
				teamMinimum: 2,
				teamMaximum: 4,
				maxTeams: 32,
				limitTeamsNumber: true,
			},
			years: {
				2018: true,
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: false,
			},
			secretPages: [ // pages that match this will not be generated when event is not visible and/or enabled (logic in gatsby-node.js)
				"/hacklica/.+"
			]
		},
		worklica: {
			order: 3,
			active: false,
			enabled: false,
			visible: false,
			visibleInHeader: true,
			visibleInHistory: true,
			baseUrl: "/worklica",
			ident: "worklica",
			title: "Worklica",
			logoFavIcon: "worklica-nt-nb.png",
			logoFull: "worklica-wt.svg",
			logoFullLight: "worklica-wb.svg",
			logoHeader: "worklica-nt.svg",
			logoSelection: "worklica-nt-nb.svg",
			baseColor: "#eb689d",
			options: {},
			years: {
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: false,
			},
		},
	},
	press: [
		{
			when: "2019-05-16",
			title: "FOI-jevci osvojili natjecanje u Čakovcu - predstavili rješenje koje koristi umjetnu inteligenciju",
			url: "https://evarazdin.hr/nasim-krajem/foi-jevci-na-natjecanju-u-cakovcu-revolucionarnim-rjesenjem-osvojili-prvo-mjesto-i-18-tisuca-kuna-377765/",
		},
		{
			when: "2019-05-13",
			title: "FOTO: Peta Weblica okupila IT stručnjake iz Međimurja",
			url: "https://radio1.hr/foto-peta-weblica-okupila-it-strucnjake-iz-medimurja/",
		},
		{
			when: "2019-05-11",
			title: "FOTO Čakovec po peti put okupljalište IT stručnjaka - otvorena Weblica",
			where: "emedjimurje.net.hr",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3448313/foto-cakovec-po-peti-put-okupljaliste-it-strucnjaka-otvorena-weblica/",
		},
		{
			when: "2019-05-10",
			title: "Sutra počinje 5. Weblica u Čakovcu!",
			url: "https://www.mnovine.hr/medimurje/sutra-pocinje-5-weblica-u-cakovcu/",
		},
		{
			when: "2018-05-16",
			title: "FOTO Weblica je fenomen koji se rijetko viđa u Međimurju",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3000161/foto-weblica-je-fenomen-koji-se-rijetko-vida-u-medimurju/",
		},
		{
			when: "2018-05-08",
			title: "Ususret Weblici: U Čakovec u subotu stižu najbolji hrvatski IT stručnjaci!",
			url: "https://www.mnovine.hr/hrvatska/aktualno/ususret-weblici-u-cakovec-u-subotu-stizu-najbolji-hrvatski-it-strucnjaci/",
		},
		{
			when: "2018-04-23",
			title: "Hacklica – 24 satni hackathon u sklopu konferencije Weblica 2018.",
			url: "https://www.foi.unizg.hr/hr/novosti/hacklica-24-satni-hackathon-u-sklopu-konferencije-weblica-2018",
		},
		{
			when: "2018-04-22",
			title: "Ponovno okupljanje jake IT scene sa sjevera na 4. izdanju Weblice",
			url: "https://www.mnovine.hr/hrvatska/aktualno/ponovno-okupljanje-jake-it-scene-sa-sjevera-na-4-izdanju-weblice/",
		},
		{
			when: "2017-05-17",
			title: "WEBLICA pozicionira Međimurje kao informatičku regiju",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3028073/weblica-pozicionira-medimurje-kao-informaticku-regiju/",
		},
		{
			when: "2017-05-16",
			title: "Weblica u Čakovcu okupila programere i dizajnere, zanimljivo predavanje Damira Sabola",
			url: "https://www.mnovine.hr/lifestyle/auto-i-techno/weblica-cakovcu-okupila-programere-dizajnere-zanimljivo-predavanje-damira-sabola/",
		},
		{
			when: "2017-05-11",
			title: "Konferencija o web tehnologijama Weblica 2017. ove subote u TICM-u",
			url: "https://medjimurska-zupanija.hr/2017/05/11/konferencija-o-web-tehnologijama-weblica-2017-ove-subote-u-ticm-u/",
		},
		{
			when: "2017-05-11",
			title: "WEBLICA Treće izdanje konferencije otvara računalni genij Damir Sabol",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3028581/weblica-trece-izdanje-konferencije-otvara-racunalni-genij-damir-sabol/",
		},
		{
			when: "2017-04-11",
			title: "WEBLICA 2017 - konferencija o web tehnnologijama",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3031127/weblica-2017-konferencija-o-web-tehnnologijama/",
		},
		{
			when: "2016-05-17",
			title: "Weblica 2016. - skup stručnjaka za umreženi svijet",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3054707/weblica-2016-skup-strucnjaka-za-umrezeni-svijet/",
		},
		{
			when: "2016-04-21",
			title: "WEBLICA 2016 – konferencija o web tehnologijama u „staroj vojarni“",
			where: "zimo.dnevnik.hr",
			url: "https://zimo.dnevnik.hr/clanak/weblica-2016-konferencija-o-web-tehnologijama-u-zstaroj-vojarni---434407.html",
		},
		{
			when: "2016-04-04",
			title: "Tehnološka konferencija Weblica 2016. ponovo u Čakovcu",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3058253/tehnoloska-konferencija-weblica-2016-ponovo-u-cakovcu/",
		},
		{
			when: "2015-05-19",
			title: "Međimurska konferencija Weblica nadmašila sva očekivanja",
			url: "https://zimo.dnevnik.hr/clanak/medjimurska-konferencija-weblica-nadmasila-sva-ocekivanja---400955.html",
		},
		{
			when: "2015-05-13",
			title: "Održana tehnološka konferencija: U IT sektoru nedostaje kadrova",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3080467/tehnoloska-konferencija-u-medimurju-u-it-sektoru-nedostaje-kadrova/",
		},
		{
			when: "2015-04-02",
			title: "WEBLICA - prva prava tehnološka konferencija med Murom i Dravom",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3083151/weblica-2015-prva-prava-tehnoloska-konferencije-med-murom-i-dravom/",
		},
		{
			when: "2024-04-15",
			title: "Weblica 2024 - Ponovni susret IT entuzijasta u Čakovcu",
			url: "https://www.bug.hr/promo/weblica-2024-ponovni-susret-it-entuzijasta-u-cakovcu-40107",
		},
		{
			when: "2024-04-05",
			title: "Weblica se vraća",
			url: "https://rep.hr/vijesti/edukacija-i-dogadanja/weblica-se-vraca/9630/",
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://emedjimurje.net.hr/vijesti/drustvo/2951405/foto-odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/",
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://www.mnovine.hr/medimurje/drustvo/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/",
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://muralist.hr/kultura/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/",
		},
		{
			when: "2024-05-27",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://www.bug.hr/dogadjaji/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it-41159",
		},
	],
}
