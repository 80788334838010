module.exports = {
  general: {
    generic_page_title: "Weblica",
    cookie_consent: {
      title: "Cookies",
      msg: [
        "<p><b>This website uses cookies</b></p>",
        "<p>We use cookies only to track page statistics.</p>",
        "<p>By clicking the cookie icon the bottom of the page, you can change consent at any time.</p>",
        '<p>If you want to know more, please check our <a href="{{url}}" target="_blank">Privacy Policy.</a></p>',
      ],
      btn_yes: "Accept",
      btn_no: "Decline",
    },
    inactive_event: "Skipping it this year!",
    inactive_survey: "Survey is not active for given event!",
    no_data: "No data!",
    no_data_list_intro:
      "We are collecting & preparing the data, but in the meantime, here's something to fill in the current gap:",
    no_data_list: [
      "Software and cathedrals are much the same — first we build them, then we pray.",
      "Don’t worry if it doesn’t work right. If everything did, you’d be out of a job.",
      "Walking on water and developing software from a specification are easy if both are frozen.",
      "C programmers never die. They are just cast into void.",
      "Some people when confronted with a problem think: ”I know, I’ll use regular expressions.”. Now they have two problems.",
      "If the box says, ‘This software requires Windows XP or better,’ does that mean it’ll run on Linux?",
      "There are only two hard things in computer science: cache invalidation and naming things.",
      "Hardware is made to last. Software is made to change. Change is the only thing that lasts. Software wins.",
      "There is no Ctrl-Z in life.",
      "Software and cathedrals are much the same — first we build them, then we pray.",
      "Don’t worry if it doesn’t work right. If everything did, you’d be out of a job.",
      "Perl: The only language that looks the same before and after RSA encryption.",
    ],
    toggle_theme: "Toggle theme",
    inaccessible_page: "This page is currently not available!",
    secret_page: "This page will not be generated for production!",
    survey_button: "Fill out the survey", // and tell us what we can do even better!",
    survey_button_short: "Fill out the survey",
    survey_button_last_year: "Fill out the survey for last year",
    finished_title: [ // default/fallback
      "<p><b>Thank you!</b><br/>See you again next year!</p>"
    ],
    finished_text: [ // default/fallback
      'Watch this space for updates or join our <a href="#newsletter">newsletter</a> and stay in the loop!',
    ],
    form: {
      full_name: "Name and Surname",
      full_name_not_required: "Name and Surname (not required)",
      full_name_alt: "Full name",
      full_name_alt_not_required: "Full name (not required)",
      first_name: "First name",
      last_name: "Last name",
      email: "E-mail",
      pin: "PIN",
      pin_placeholder_visitor: "Enter PIN that you received upon registering at event",
      phone: "Mobile",
      title: "Title",
      function: "Function",
      company: "Company",
      web: "Web",
      message: "Message",
      optional_placeholder: "(optional)",
      bool_yes: "Yes",
      bool_no: "No",
      option_null: "(none of the above)",
      clear_all_values: "Clear",
      send_data: "Send",
      info_text:
        'Please check our <a href={{url}} target="_blank" rel="noreferrer">Privacy policy</a> for info about data processing before sending this data!',
      msgs: {
        sending_data: "Sending",
        data_sent: "Form sent successfully!",
        full_name_missing: "Full name is missing!",
        first_name_missing: "First name is missing!",
        last_name_missing: "Last name is missing!",
        email_missing: "E-mail is missing!",
        email_format: "E-mail is not correctly formatted!",
        pin_missing: "PIN is missing!",
        pin_format: "PIN is not correctly formatted!",
        pin_unknown: "PIN is unknown!",
        phone_missing: "Mobile number is missing!",
        phone_format: "Mobile number is not correctly formatted!",
        server_error: "Server side error!",
        server_dead: "Server not responding!",
        exists: "Data you have sent already exists!",
        required_missing: "One or more required fields are not filled in!"
      },
    }
  },
  pages: {
    home: {
      title: "Insight into the world of web technologies",
      meta: {
        description:
          "Annual regional conference on web technologies with accompanying events such as hackathon and workshops",
        keywords:
          "weblica, hacklica, worklica, conference, hackathon, workshop, IT, developer, software, devops, web3, php, javascript, node, .net, css, html, react, vue, angular, development, collaboration, design, implementation",
      },
      view_event: "View Event",
      register_team: "Register your team",
      get_tickets: "Get Tickets",
      register_lecture: "Call for Speakers",
      sponsor: "Become a Sponsor",
    },
    code: {
      title: "Code of Conduct",
      meta: {
        description:
          "Code of Conduct can be very simple - Be excellent with each other",
        keywords: "code, conduct",
      },
    },
    terms: {
      title: "Terms and Conditions",
      meta: {
        description: "Terms and Conditions",
        keywords: "terms, conditions",
      },
    },
    privacy: {
      title: "Privacy Policy",
      meta: {
        description: "Privacy Policy",
        keywords: "privacy, policy",
      },
    },
    safety: {
      title: "Health & Safety",
      meta: {
        description: "Health & Safety",
        keywords: "health, safety",
      },
    },
    press: {
      title: "Press & Media kit",
      meta: {
        description: "Press & Media kit",
        keywords: "press, media kit, logotypes",
      },
      reg_title: "Registration",
      reg_msg:
        "We welcome journalists to cover our events, free of charge. You just need to fill out on the form below to register and our media team will get back to you as soon as possible.",
      reg_button: "Register for your Press Pass",
      mentions_title: "Articles",
      media_title: "Media Kit",
      media_weblica_logotypes: "Weblica - logotypes (PNG & SVG)",
      media_hacklica_logotypes: "Hacklica - logotypes (PNG & SVG)",
      media_worklica_logotypes: "Worklica - logotypes (PNG & SVG)",
      colors_title: "Colors",
    },
    sponsors: {
      title: "Become a Sponsor",
      meta: {
        description:
          "Your support is crucial for the high-quality realization of the conference that will gather over 200 participants. By sponsoring, you not only contribute to the development of the IT sector in the region but also gain the opportunity to promote your offerings to a relevant IT audience.",
        keywords: "sponsoring, sponsor, help, organization, support",
      },
      welcome: "Welcome",
      date_start:
        "Registration will ne available from {{date1num}} up to {{date2num}}",
      date_end:
        "Registration is no longer available! The deadline was set at {{date2num}}",
      btnDownload: "Download the Brochure",
      message: [
        "<p>Thank you for your interest in sponsorship.</p>",
        "<p>Please fill out the form below. Once we receive your information, we will send you a sponsorship package offer via email.</p>",
        "<p>Please note that the deadline for application is {{date2num}}, by the end of the day.</p>",
      ],
      message_download: [
        "<br/><p>You can also download the sponsorship package offer by using the button below.</p>",
      ],
      form: {
        interested_in: "I am also interested in:",
        interest_visitor: "Attending as visitor",
        interest_speaker: "Being a speaker",
        interest_booth: "Having info-booth",
      },
    },
    exhibitors: {
      title: "Become an Exhibitor",
      meta: {
        description:
          "Your support is crucial for the high-quality realization of the conference that will gather over 200 participants. By exhibiting, you not only contribute to the development of the IT sector in the region but also gain the opportunity to promote your offerings to a relevant IT audience.",
        keywords: "exhibiting, help, organization, support",
      },
      welcome: "Welcome",
      date_start:
        "Registration will ne available from {{date1num}} up to {{date2num}}",
      date_end:
        "Registration is no longer available! The deadline was set at {{date2num}}",
      btnDownload: "Download the Brochure",
      message: [
        "<p>Thank you for your interest in exhibiting.</p>",
        "<p>Please fill out the form below. Once we receive your information, we will send you a brochure via email.</p>",
        "<p>Please note that the deadline for application is {{date2num}}, by the end of the day.</p>",
      ],
      message_download: [
        "<br/><p>You can also download the brochure by using the button below.</p>",
      ],
      form: {
        interested_in: "I am also interested in:",
        interest_visitor: "Attending as visitor",
        interest_speaker: "Being a speaker",
        interest_booth: "Having info-booth",
      },
    },
    survey: {
      title: "Survey - {{eventName}} {{currentYear}}",
      meta: {
        description: "",
        keywords: "",
      },
      loading: "Loading",
      unknown_event: "Unknown event!",
      anonymous: "Anonymize personal data",
      anonymous_remark: [
        "<p>Name and e-mail are sent as anonymized data and serve only to differentiate the survey data - without any possibility of identification.</p>",
      ],
      welcome: "Welcome",
      message: [
        "<p>Thank you for taking the time to fill out this survey.<br/>",
        "Your opinion is extremely important to us, so that we can be even better in the future.</p>",
        "<p>In addition to the general questions, you can rate the sessions and speakers individually.</p>"
      ],
      message_speaker: [
        "<p>Thank you for taking the time to fill out this survey.<br/>",
        "Your opinion is extremely important to us, so that we can be even better in the future.</p>",
        "<p>In addition to the general questions, you can rate the sessions and speakers individually.</p>"
      ],
      message_single_use: [
        "This survey can be sent only once and can not be corrected after sending.<br/>Please take your time with giving answers."
      ],
      message_event_not_started: [
        "<p><b>{{eventName}} {{currentYear}}</b> starts <b>{{date1}}</b> and ends <b>{{date2}}</b>",
        "<p>The survey will be available after event ends.</p>"
      ],
      message_event_not_finished: [
        "<p><b>{{eventName}} {{currentYear}}</b> is currently active and ends on <b>{{date2}}</b>.</p>",
        "<p>The survey will be available after event ends.</p>"
      ],
      privacy_statement: [
        // '<p>All data is processed in accordance with our <a href="{{privacyUrl}}" target="_blank" rel="noreferrer">Privacy Policy</a> and is used solely to improve future events.</p>',
        "<p>All data is processed in accordance with our <a href='{{privacyUrl}}' target='_blank' rel='noreferrer'>Privacy Policy</a> and used exclusively for the purposes listed below.</p>",
        "<p>By completing the questionnaire, you agree to the collection and further processing of your personal data that you have entered in the web form <b>„Questionnaire - Weblica 2024“</b> via the website at the website <a href='https://weblica.hr/'>https://weblica.hr/</a>, which includes the trading company WEBLICA j.d.o.o., with headquarters in Čakovec ( City of Čakovec), Športska ulica 6A, OIB: 67304695703 (hereinafter: „Processing Manager“), as well as that the Processing Manager collects, processes and uses your personal data for the purpose of improving future events that would be organized by the Processing Manager and in the purpose of sharing them with the partners/sponsors of the „Weblica“ conference, for the fulfillment of marketing purposes by the partners/sponsors of the Controller.</p>",
        "<p>It is a voluntary provision of data, and you are not obliged to give your consent, or you are not obliged to enter your personal data in the web form in question. . on the protection of individuals in connection with the processing of personal data and on the free movement of such data and on the repeal of Directive 95/46/EC (General Data Protection Regulation), the Law on the Implementation of the General Data Protection Regulation (Official Gazette No. 42/2018 ), as well as the Privacy Policy for the protection of personal data in WEBLICA j.d.o.o., all with the application of appropriate organizational and technical measures to protect personal data from unauthorized access, misuse, disclosure, loss or destruction.</p>",
        "<p>The data is collected permanently for the reason that, until the consent is withdrawn, the Data Controller considers that there is an interest of the respondent for the purpose for which it collects, processes and uses the personal data of the respondent. The subject has the right at any time, in whole or in part, without compensation and/or explanation, to withdraw the consent at any time, without this affecting the legality of the processing that was based on the consent before it was withdrawn. The respondent has the right at any time, in whole or in part, without compensation and/or explanation, to obtain from the Data Controller the deletion of personal data relating to him without undue delay. Also, the subject has the right at any time, in whole or in part, without compensation and/or explanation, to request from the Controller access to personal data and correction or deletion of personal data or restriction of processing related to the subject or the right to object to the processing of such and data portability rights. The subject can exercise his rights by contacting the Data Controller, i.e. the Personal Data Protection Officer, Davor Tarandek, by mail to the address of the headquarters of the trading company WEBLICA j.d.o.o., Športska ulica 6A, 40000 Čakovec or by electronic means to the following email address: <a href='mailto:info@weblica.hr'>info@weblica.hr</a>. The respondent has the right to submit a complaint to the supervisory authority, i.e. the Agency for the Protection of Personal Data (AZOP), Zagreb, Selska cesta 136, e-mail address: <a href='mailto:azop@azop.hr'>azop@azop.hr</a>.</p>"
      ],
      questions: "Questions",
      sessions: "Sessions and Speakers",
      speaker: "Speaker",
      speakers: "Speakers",
      at: "at",
      form: {
        optional_placeholder: "(comment optional)",
        msgs: {
          exists: "Answers for {{eventName}} {{currentYear}} survey were already sent!"
        }
      }
    },
    press_register: {
      title: "Press Pass Registration",
      meta: {
        description:
          "Presence in the modern media space is extremely important for any event like ours, so don't hesitate. If you are a journalist and follow the IT scene, you will certainly find our events interesting.",
        keywords:
          "press, journalist, journalists, IT, conference, workshop, hackathon, website, hacklica, worklica",
      },
      welcome: "Welcome",
      date_start:
        "Registration will ne available from {{date1num}} up to {{date2num}}",
      date_end:
        "Registration is no longer available! The deadline was set at {{date2num}}",
      message: [
        "<p>Thank you for the interest in covering our events.</p>",
        "<p>Media accreditation is subject to assessment and approval by our media team, which will be in touch with you very soon after receiving the registration.</p>",
        "<p>Please note that the deadline for application is {{date2num}}, by the end of the day.</p>",
        '<p>If you intend to come to our events with a crew (photographer, cameraman, etc.), just enter team member names (and function if possible) in the field "Team Members".<p>',
      ],
      form: {
        team_members: "Team Members (name surname, function)",
        cover: "I want to cover following events:",
      },
    },
    volunteer: {
      title: "Become a Volunteer",
      meta: {
        description: "Become a Volunteer",
        keywords: "volunteer, volunteering, help, free access",
      },
      welcome: "Welcome",
      date_start:
        "Registration will ne available from {{date1num}} up to {{date2num}}",
      date_end:
        "Registration is no longer available! The deadline was set at {{date2num}}",
      message: [
        "<p>By volunteering, you contribute to the success of the event and have the opportunity to attend lectures that interest you.</p>",
        "<p>Fill out a short application, and we'll get in touch with additional information.</p>",
        "<p>Please note that the deadline for application is {{date2num}}, by the end of the day.</p>",
      ],
      form: {
      },
    },
    error404: {
      title: "Error - 404",
      meta: {
        description: "",
        keywords: "",
      },
      message: "Unfortunately, the requested page does not exist!",
      home_link: "Home Page",
    },
    maintenance: {
      meta: {
        description: "",
        keywords: "",
      },
      title: "Maintenance",
      message:
        "Site is under maintenance.<br/>Please refresh in a few minutes.",
    },
    recording: {
      title: "Notice about photography and recording",
      meta: {
        description: "",
        keywords: "",
      },
      text: [
        "<p>During our events, our team and official photographers and videographers will be present to capture moments from the event. Please be aware that photography, video, and audio recording may occur throughout the event.</p>",
        "<p>The recorded material may be used for promotional purposes, including but not limited to, posting on our websites, social media, and in digital materials. By participating in the conference, you give us the right to use these materials without asking for additional permission.</p>",
        "<p>If you have any questions or concerns about this, or if you do not wish to be recorded, please contact organizers or via email at <a href='mailto:info@weblica.hr' target='_blank'>info@weblica.hr</a>.</p>",
        "<p>Thank you for your understanding and cooperation!</p>"
      ]
    },
    unsupportedLanguage: {
      title: "Unsupported translation/active",
      notSupported: "Translation is not supported or active!",
      defaultPageLink: `Please use <a href="{{originalPath}}">default page</a>.`,
      redirecting: "Redirecting to default page in {{timeout}}&nbsp;"
    }
  },
  sections: {
    schedule: {
      title: "Schedule",
      title_handle: "Schedule",
      expand_all: "Expand All",
      show_starred: "Starred Only",
      all_tracks: "All tracks",
      all_tracks_button: "Show all tracks",
      track1: "Track 1",
      track2: "Track 2",
      track3: "Track 3",
      track4: "Track 4",
      nothing_starred: "There are no starred items!",
      language: "Language",
      talk_level: "Level",
      talk_level_novice: "Novice",
      talk_level_intermediate: "Intermediate",
      talk_level_expert: "Expert",
      code_amount: "Code Amount",
      code_amount_none: "None",
      code_amount_light: "Light",
      code_amount_heavy: "Heavy",
      tags: "Tags",
      work_in_progress: "More soon",
      full_page: "Full Page",
      full_page_hint: "Show schedule in a separate page",
      notification_bell: "Bell",
      notification_bell_hint: "Current schedule item change will be announced by a sound.",
      notification_bell_warning: "Sounds are blocked by browser. Please click on this message to enable sounds!",
      notification_vibrate: "Vibration",
      notification_vibrate_hint: "Current schedule item change will be announced by vibration (on mobile devices).",
      powered_by: "Powered by",
      tbd: "TBD",
      preview_mode: "While schedule is being prepared, check out some of the sessions that await us this year.",
      aria_starred: "Starred",
      aria_unstarred: "Not starred",
      keynote: "Keynote"
    },
    footer: {
      history: {
        title: "History",
      },
      more: {
        title: "More",
        code: "Code of Conduct",
        terms: "Terms and Conditions",
        privacy: "Privacy Policy",
        safety: "Health & Safety",
        press: "Press & Media kit",
        sponsor: "Become a Sponsor",
        exhibitor: "Become an Exhibitor",
        volunteer: "Become a Volunteer",
        recording: "Notice about photography and recording"
      },
      contact: {
        title: "Contact",
      },
      legal: {
        title: "Legal Info",
        info: "Weblica j.d.o.o., OIB: 67304695703. Entered into the court register of the Commercial Court in Varaždin under number 070165706. Share capital HRK 30.00 paid in full. CEO: Davor Tarandek",
        account:
          "IBAN: HR1724020061100898603, SWIFT/BIC: ESBCHR22 at Erste&Steiermärkische Bank d.d.",
        copyright:
          "{{yearStart}}-{{yearCurrent}} © Weblica j.d.o.o. / All rights reserved",
      },
      created: "Created and supported by {{fx}} and {{tria}}",
    },
    sponsors: {
      title: "Sponsors",
      title_handle: "Sponsors",
      titleExhibitors: "Exhibitors",
      titleSponsorsAndPartners: "Sponsors & Partners",
      btnDownload: "Download the Brochure",
      btnRegister: "Become a Sponsor",
      btnRegisterExhibitor: "Become an Exhibitor",
      partners: "Partners",
      media_partners: "Media Partners",
      levels: {
        l5: "Platinum",
        l4: "Gold",
        l3: "Silver",
        l2: "Bronze",
        l1: "?",
      },
      packages: {
        web: "Web",
        lunch: "Lunch",
        coffee: "Coffee",
        snacks: "Snacks",
        accreditations: "Accreditations",
        water: "Branded Water",
        soft_drinks: "Soft Drinks",
        pens: "Pens",
        notepads: "Notepads",
        photo: "Photo Booth"
      },
    },
    news: {
      title: "News",
      title_handle: "News",
      expand_all: "Expand All",
    },
    faq: {
      title: "Frequently Asked Questions",
      title_handle: "Frequently Asked Questions",
      expand_all: "Expand All",
    },
    testimonials: {
      title: "Testimonials",
      title_handle: "Testimonials",
      expand_all: "Expand All",
      speaker: "Speaker",
    },
    historyGallery: {
      title: "Prior events",
      title_handle: "Prior events",
      content: [
        "<p>The past conferences and other events were extremely interesting - check out why.</p>",
      ],
      aria_item_image: "Example Image"
    },
    newsletter: {
      title: "Newsletter",
      title_handle: "Newsletter",
      content: "Join our mailing list and be up to speed.",
      form: {
      },
    },
  },
  weblica: {
    meta: {
      titlePrefix: "Web Technology conference",
      description: "Web Technology conference",
      keywords:
        "weblica, conference, programmer, programming, development, software, devops, web3, php, javascript, node, .net, css, html, react, vue, angular, development, collaboration, design, implementation",
    },
    menu: {
      about: "About",
      news: "News",
      speakers: "Speakers",
      schedule: "Schedule",
      tickets: "Tickets",
      venue: "Venue",
      sponsors: "Sponsors",
      faq: "FAQ",
      testimonials: "Testimonials",
    },
    buttons: {
      home: "/",
      tickets: "Get Tickets",
      lecture: "Call for Speakers",
      sponsor: "Become a Sponsor",
      exhibitor: "Become an Exhibitor",
      volunteer: "Become a Volunteer",
    },
    pages: {
      speaker: {
        intro: "I'm speaking at conference <span>{{event}} {{year}}</span>"
      },
      history: {
        meta: {
          description: "Weblica History",
          keywords: "weblica, history, speakers, sessions, video, images",
        },
        title: "Weblica History",
        talks: "Talks and Speakers",
        no_data: "Data is missing!",
        browse_images: "Browse images from the event",
        images: "Photo Gallery",
        when_where: "When and where",
        when: "Date",
        where: "Venue",
        sponsors_partners: "Sponsors & Partners",
      },
    },
    sections: {
      hero: {
        title: "Weblica",
        subtitle: "Web Technology conference",
        tags: ["Development", "Collaboration", "Design", "Implementation"],
        intro: [],
        preparation: [
          'Watch this space for updates or join our <a href="#newsletter">newsletter</a> and stay in the loop!',
        ],
      },
      about: {
        title: "",
        title_handle: "About",
        txt_aaa: [
          "<p>Weblica is creating a platform for the development of the IT sector in the region, attracting a wide range of participants, from developers and designers to, all kinds of, managers, DevOps experts, students, and anyone interested in the world of web technologies.</p>",
          "<p>The focus of Weblica is on delivering valuable and in-depth knowledge in a friendly environment, while the lectures are an endless source of practical solutions.</p>",
          "<p>Active participation in interactive discussions and networking with like-minded individuals will enable you to immediately apply the acquired knowledge in practice.</p>",
        ],
        txt_bbb: [
          "<div class='h3'>Guidelines for topic selection</div>",
          "<p>Weblica, as a conference on web technologies, covers a wide range of potential topics for discussion. This list of topics serves as a guide for the content to be covered at the conference, but is <b>not</b> limited to the areas mentioned. The world of web tech is vast, so we welcome suggestions for topics not listed here but hold value in the IT sector.</p>",
          "<p>Given that our audience consists of industry experts, we encourage speakers not to shy away from diving into technical details in their talks.</p>",
          "<ul class='spec-box-tag-cloud {{event}}'>",
          "{{eventTopicsTags}}",
          "</ul>",
        ],
        txt_ccc: [
          "<div class='h3'>Previous editions in numbers</div>",
          "<ul class='spec-box-numbers'>",
          "<li><span>{{statVisitors}}+</span> attendees</li>",
          "<li><span>{{statSpeakers}}+</span> expert speakers</li>",
          "<li><span>{{statBeers}}+</span> liters of beer consumed</li>",
          // "<li><span>{{statDrinks}}+</span> litres of non-alcoholic drinks consumed (coffee, soft-drinks)</li>",
          // "<li><span>{{statBurgers}}+</span> gourmet burgers eaten</li>",
          "<li><span>&infin;</span>endless knowledge and inspiration</li>",
          "</ul>",
          "High-quality food, great coffee, and a selection of beers provide an additional reason to attend Weblica.</p>",
        ],
      },
      speakers: {
        title: "Speakers",
        title_handle: "Speakers",
        register: "Call for Speakers",
        work_in_progress: "More soon",
        keynote: "Keynote",
        expand_all: "Expand All",
      },
      tickets: {
        title: "Tickets",
        title_handle: "Tickets",
        description: "Ensure your place at Weblica {{currentYear}} and become part of our community.",
        stream: "Stream",
        live: "Live Conference",
        goodies: "Goodies bag",
        extra_goodies: "Extra goodies in the bag",
        lunch: "Lunch, snacks & drinks",
        activity_day: "Activity Day",
        order_now: "Order Now",
        ticket_not_available: "Not available any more!",
        plus_vat: " + VAT",
        from: "from",
        to: "to",
        now: "&#9737;",
        remaining_tickets: "Available tickets: <b>{{remainingTickets}}</b>",
        remaining_tickets_off:
          "<b>There are no tickets available anymore. Sorry!</b>",
        price_alt_remark:
          "Calculated by using fixed conversion rate of {{altConversionRate}}",
        price_vat_remark: "Prices include VAT",
      },
      venue: {
        title: "Venue",
        title_handle: "Venue",
        map_link_text_om: "Open Open Street Map in new tab",
        map_link_text_gm: "Open Google Map in new tab",
        parking: "Parking",
        parking_text:
          "Free parking is available for all participants within the facility where the conference will be held.",
        accommodation: "Accommodation",
        accommodation_text:
          "If you need help or recommendations regarding accommodation, feel free to contact us.",
        accessibility: "Accessibility",
        accessibility_text: "Access for people with disabilities is ensured.",
      },
    },
  },
  hacklica: {
    meta: {
      titlePrefix: "24-hour hackathon",
      description: "24-hour hackathon",
      keywords: "hachathon, engagement, 24 hours, prizes, money, prestige",
    },
    menu: {
      about: "About",
      news: "News",
      topics: "Topics",
      venue: "Venue",
      sponsors: "Sponsors",
      faq: "FAQ",
      terms: "Terms",
      policy: "Policy",
    },
    buttons: {
      home: "/",
      register: "Register your team",
      sponsor: "Become a Sponsor",
    },
    pages: {
      register: {
        meta: {
          description: "Hackathon Team Registration",
          keywords: "hackathon, team, registration",
        },
        title: "Team Registration",
        date_start:
          "Registration will be available from {{date1num}} up to {{date2num}}",
        date_end:
          "Registration is no longer available! The deadline was set at {{date2num}}",
        maxedOut: [
          "<p>Number of registered teams has reached predefined limit!<br/>",
          "Sorry, but you need to be faster next year!</p>",
        ],
        message: [
          "<p>We are glad that you decided to compete in our hackathon!</p>",
          "<p>After sending the form you will receive a confirmation e-mail. Please click on the link inside to make a final confirmation.</p>",
          '<p>Before applying, we highly recommend that you read our <a href="{{code}}" target="_blank" rel="noreferrer">Code of Conduct</a> and our <a href="{{terms2}}" target="_blank" rel="noreferrer">Hackathon Terms</a>.</p>',
          "<p>Registration is possible by the end of the day on <b>{{date2num}}</b> and number of teams is limited to <b>{{maxTeams}}</b>.</p>",
        ],
        form: {
          team_name: "Team name",
          team_email: "E-mail",
          team_count: "Members count",
          member_name: "Full name",
          member_tshirt: "T-shirt size",
          member_vege: "Vegetarian",
          member_lead: "Team Leader",
          shirts_info: "All shirts will be personalized with your team name!",
          msgs: {
            team_name_missing: "Team name is missing!",
            team_email_missing: "Team e-mail is missing!",
            team_lead_missing: "You did not select a team leader!",
            team_email_format: "Team e-mail is not correctly formatted!",
            member_name_missing: "Member name is missing!",
            team_count_max: "Number of teams has reached maximum!"
          },
        },
      },
      confirmation: {
        meta: {
          description: "Hackathon Team Confirmation",
          keywords: "hackathon, team, confirmation",
        },
        title: "Your application for Hacklica Hackathon is successful!",
        at: "at",
        message: [
          "<p>You have successfully registered your team for the Hacklica Hackathon.</p>",
          "A brief reminder - we start on <b>{{date}} {{at}} {{time}}</b> on location <b>{{venue}}</b>.</p>",
          '<p>Food, drinks, and recreational activities will be provided and all you need to bring is your equipment for work. <span class="info-text">*</span></p>',
          "<p><b>We wish you the best of luck!</b></p>",
          '<p class="info-text">* changes may apply</p>',
        ],
        __message: [
          "<p><b>Thank yor for registering to Hacklica Hackathon!</b></p>",
          "<p>Your registration is now confirmed and we will be expecting you on {{date}} {{at}} {{time}}!</p>",
          "<p>If you can not attend, please try to inform us at least few days before the hackathon.</p>",
        ],
      },
      history: {
        meta: {
          description: "Hacklica History",
          keywords: "hacklica, hackathon, 24 hours, history",
        },
        title: "Hacklica History",
        teams: "Teams",
        no_data: "Data is missing!",
        browse_images: "Browse images from the event",
        images: "Photo Gallery",
        when_where: "When and where",
        when: "Date",
        where: "Venue",
        sponsors_partners: "Sponsors & Partners",
      },
      terms: {
        meta: {
          description: "Hacklica Hackathon Terms",
          keywords: "hacklica, hackathon, terms",
        },
        title: "Hacklica Hackathon Terms",
      },
    },
    sections: {
      hero: {
        title: "Hacklica",
        subtitle: "24-hr hackathon",
        tags: [],
        __intro: [
          "<p>Hacklica is a logical extension of our efforts to promote software development in our region. It was a success from the start, but we will be making it even better.</p>",
        ],
        preparation: [
          'Watch this space for updates or join our <a href="#newsletter">newsletter</a> and stay in the loop!',
        ],
        txt_1: [
          "<p>Teams embark on an exciting journey with every hackathon, where they develop advanced solutions, push creative boundaries, and turn ideas into reality. The clock is ticking, but the possibilities are endless!</p>",
          "<p>Innovation is the guiding principle, and teams are not expected to have a final solution, but maximum engagement is still sought to demonstrably showcase the team's abilities and potential.</p>",
        ],
        txt_2: [
          "<p>Our expert jury consists of experienced professionals who assess each solution on multiple levels - from the quality and execution of the solution to the quality of the code written, and even the presentation itself.</p>",
        ],
      },
      about: {
        title: "",
        title_handle: "About",
        txt_aaa: [
          "<p><b>Battle for the grand prize</b><br/>",
          "Teams compete for impressive cash prizes that will not leave anyone indifferent. In addition, of course, all participants, especially the winners, have a legitimate reason to brag in front of their colleagues and friends, as well as future employers.</p>",
          "<p><b>Inspiring themes</b><br/>",
          "There is always a selection of exciting and current topics that give some direction to what will be done, that are free enough to stimulate your imagination and highlight your technical skills. Each team independently chooses one of the offered topics and has the opportunity to show their abilities.</p>",
          "<p><b>Food and drink are provided, as well as numerous forms of entertainment</b><br/>",
          "Your comfort is our priority. That is why sufficient quantities of various delicious meals, from meat to vegetarian dishes, and soft drinks are provided. Of course, it goes without saying that you will be able to quickly fill up with energy with various snacks and fruits. If you have special wishes, just let us know and we will do our best to provide it.</p>",
          "<p>In terms of entertainment, we've prepared a bunch of fun activities and surprises, so you'll be able to relax and connect with others, whether you're a fan of games, sports or relaxing with a friendly chat.</p>",
        ],
        txt_bbb: [],
        txt_ccc: [],
      },
      topics: {
        title: "Topics",
        register_team: "Register your team",
        message: [
          "<p>The topics mentioned here are intended as an orientation for the teams that plan to attend.</p>",
          "<p>We are disclosing this just to help you out a bit and let you mentally prepare for the hackathon. Please do not get tempted and develop some or all of the solution before the event itself. We will know, we will disqualify you, shame you publicly and on top of that, we will ban you from all of our future events. For life.</p>",
          "<hr/>",
        ],
      },
      venue: {
        title: "Venue",
        title_handle: "Venue",
        map_link_text_om: "Open Open Street Map in new tab",
        map_link_text_gm: "Open Google Map in new tab",
        parking: "Parking",
        parking_text:
          "Free parking is available for all participants within the facility where the conference will be held.",
        accommodation: "Accommodation",
        accommodation_text:
          "If you need help or recommendations regarding accommodation, feel free to contact us.",
        accessibility: "Accessibility",
        accessibility_text: "Access for people with disabilities is ensured.",
      },
    },
    timer: {
      init: "Initialization...",
      waiting: "Waiting to start!",
      waiting_days: "Get ready! We will start in few days!",
      waiting_long: "There a lot of time until start!",
      in_progress: "Hackathon is in progress!",
      done: "The hackathon is over! Thank you all!",
      hr: "hr",
      min: "min",
      sec: "sec",
      msec: "ms",
    },
  },
  worklica: {
    meta: {
      titlePrefix: "Workshops for developers",
      description: "Workshops for developers",
      keywords: "worklica, workshop, workshops, developer, developers",
    },
    menu: {
      about: "About",
      news: "News",
      speakers: "Speakers",
      schedule: "Schedule",
      tickets: "Tickets",
      venue: "Venue",
      sponsors: "Sponsors",
      faq: "FAQ",
    },
    buttons: {
      home: "/",
      tickets: "Get Tickets",
      register: "Register workshop",
      sponsor: "Become a Sponsor",
    },
    pages: {
      history: {
        meta: {
          description: "Worklica History",
          keywords: "worklica, workshop, workshops, history",
        },
        title: "Worklica History",
        talks: "Workshops and Speakers",
        no_data: "Data is missing!",
        browse_images: "Browse images from the event",
        images: "Photo Gallery",
        when_where: "When and where",
        when: "Date",
        where: "Venue",
        sponsors_partners: "Sponsors & Partners",
      },
    },
    sections: {
      hero: {
        title: "Worklica",
        subtitle: "Workshops for developers",
        tags: [],
        __intro:
          "Worklica is an extension of the conference for those who love to be more proactive and learn through practical examples.",
        preparation: [
          'Watch this space for updates or join our <a href="#newsletter">newsletter</a> and stay in the loop!',
        ],
        txt_1: [
          "<p>With expert speakers or leaders for each workshop, participants get to try out new ideas, techniques, and concepts in practice on the spot.</p>",
          "<p>Most of us retain what we learn more easily and for a longer time when we learn through practice, so workshops add extra value but require additional engagement for effective participation.</p>",
        ],
        txt_2: [
          "<p>To follow the workshop, a laptop will certainly be needed. However, if for any reason you are unable to bring your equipment, please notify us in advance, and we will do our best to provide a temporary solution.</p>",
          "<p>If the workshop itself has specific technical requirements, they will be clearly indicated in the workshop schedule.</p>",
        ],
      },
      about: {
        title: "",
        title_handle: "About",
        txt_aaa: [
          "<p>Although, for well-known reasons, we have only managed to hold one Worklica, it has proven interesting to a certain number of people. Therefore, we are definitely continuing with new enthusiasm and fresh ideas.</p>",
          "<p>We understand that it can be difficult to choose between two good and interesting options, so we ensure that Weblica and Worklica will not take place at the same time. This allows participants to attend both events without having to choose.</p>",
        ],
        txt_bbb: [],
        txt_ccc: [],
      },
      speakers: {
        title: "Speakers",
        title_handle: "Speakers",
        register: "Register workshop",
        expand_all: "Expand All",
      },
      tickets: {
        title: "Tickets",
        title_handle: "Tickets",
        worklica: "Workshops",
        goodies: "Goodies bag",
        lunch: "Lunch, snacks & drinks",
        order_now: "Order Now",
        ticket_not_available: "Not available any more!",
        plus_vat: " + VAT",
      },
      venue: {
        title: "Venue",
        title_handle: "Venue",
        map_link_text_om: "Open Open Street Map in new tab",
        map_link_text_gm: "Open Google Map in new tab",
        parking: "Parking",
        parking_text:
          "Free parking is available for all participants within the facility where the conference will be held.",
        accommodation: "Accommodation",
        accommodation_text:
          "If you need help or recommendations regarding accommodation, feel free to contact us.",
        accessibility: "Accessibility",
        accessibility_text: "Access for people with disabilities is ensured.",
      },
    },
  },
};
